import { gql } from "@apollo/client"

// Consumer Side
const GET_DEALERS = gql`
  query DealersSearchWithPagination(
    $shopName: String!
    $page: Int
    $limit: Int
  ) {
    dealersSearchWithPagination(
      shopName: $shopName
      page: $page
      limit: $limit
    ) {
      dealers {
        _id
        shopName
        streetAddress
        city
        state
        zip
      }
    }
  }
`

const GET_DISTINCT_VEHICLES = gql`
  query getDistinctVehicle {
    getDistinctVehicle
  }
`
const GET_PART_NUMBER_BY_VEHICLE = gql`
  query GetPartNumberByVehicle($vehicle: String) {
    getPartNumber_Category2FromVehicle_Category1(vehicle: $vehicle) {
      partNumber
    }
  }
`
const GET_ACCESSORY_BY_PART_NUMBER = gql`
  query GetAccessoryByPartName($partNumber: String) {
    getAccessory_Category3FromPartNumber_Category2(partNumber: $partNumber) {
      accessoryBasic
    }
  }
`

const GET_MY_PROFILE_DETAILS = gql`
  query EnrolmentByUserID {
    enrolmentByUserID {
      _id
      firstName
      lastName
      street1
      street2
      city
      state
      zip
      phone
      workEmail
      smsOptIn
    }
  }
`

const GET_PRE_ENROLLMENT_DATA = gql`
  query ParticipantByEmailId($email: String!) {
    participantByEmailId(email: $email) {
      participant {
        firstName
        lastName
        workEmail
        incentiveJobCategory
        kuid
      }
      dealer {
        _id
        shopName
        streetAddress
        city
        state
        zip
      }
    }
  }
`

// Common
const GET_MY_PERMISSIONS = gql`
  query UserPermissions {
    userPermissions
  }
`

const GET_CURRENT_INCENTIVES = gql`
  query ListProductsWithActiveRewardsWithPagination(
    $page: Int
    $limit: Int
    $fromDate: String
    $toDate: String
    $category1Values: [String]
    $category2Values: [String]
    $category3Values: [String]
  ) {
    listProductsWithActiveRewardsWithPagination(
      page: $page
      limit: $limit
      fromDate: $fromDate
      toDate: $toDate
      category1Values: $category1Values
      category2Values: $category2Values
      category3Values: $category3Values
    ) {
      currentPage
      limit
      productListWithActiveRewards {
        _id
        productID
        productName
        productImage
        productDescription
        combinedField
        incentiveId
        hasClaims
        category1
        category2
        category3
        accessoryExtended
        rewards {
          _id
          rewardType
          rewardValue
          minProductsSold
          maxProductsSold
          startDate
          endDate
          isRetroActive
          dealerID
          product
          isDeactivated
          incentiveId
        }
      }
      totalDocs
      totalPages
    }
  }
`

const GET_INCENTIVE_DETAILS_BY_CATEGORY_3 = gql`
  query GetProductWithRewardsByCategory3(
    $page: Int
    $limit: Int
    $category3: String
  ) {
    getProductWithRewardsByCategory3(
      page: $page
      limit: $limit
      category3: $category3
    ) {
      product {
        _id
        productImage
        category1
        category2
        category3
        accessoryExtended
        rewards {
          rewardValue
        }
      }
      totalDocs
    }
  }
`

const GET_ANNOUNCEMENTS = gql`
  query ListAnnouncementsWithPagination($page: Int, $limit: Int) {
    listAnnouncementsWithPagination(page: $page, limit: $limit) {
      announcements {
        _id
        title
        body
        image
      }
      totalPages
      totalDocs
      limit
      currentPage
    }
  }
`

const GET_HEADER = gql`
  query GetHeader {
    getHeader {
      content
      _id
    }
  }
`
const GET_FUNDING_HISTORY = gql`
  query OrderHistoryByUserId(
    $signingInFromConsumerOrAdmin: SigningInFromConsumerOrAdmin!
    $userId: String
  ) {
    orderHistoryByUserId(
      signingInFromConsumerOrAdmin: $signingInFromConsumerOrAdmin
      userId: $userId
    ) {
      trackingNumber
      totalSumOfClaims
      fundedDate
    }
  }
`

// Admin Side
const GET_ALL_ENROLLMENTS = gql`
  query Enrolments($page: Int, $limit: Int, $searchTerm: String) {
    enrolments(page: $page, limit: $limit, searchTerm: $searchTerm) {
      enrolments {
        _id
        userId
        shopName
        dealerId {
          _id
          regionId
          districtId
        }
        firstName
        lastName
        goesByName
        jobTitle
        street1
        street2
        city
        state
        zip
        country
        phone
        workEmail
        smsOptIn
        documentUrl
        isActive
      }
      totalDocs
    }
  }
`

const GET_CLAIMS = gql`
  query ListClaimsWithPagination(
    $searchTerm: String
    $page: Int
    $limit: Int
    $status: String
    $startDate: String
    $endDate: String
    $mode: String
    $fields: [String]
  ) {
    listClaimsWithPagination(
      searchTerm: $searchTerm
      page: $page
      limit: $limit
      status: $status
      startDate: $startDate
      endDate: $endDate
      mode: $mode
      fields: $fields
    ) {
      claims {
        awardValue
        bonusValue
        brand
        dealerName
        firstName
        id
        lastName
        productIdManual
        productName
        productPurchased
        quantity
        sku
        status
        productId
        orderId
        enrolmentId
        dateOfSale
        dealerId
        declinedReasons
        productDescription
        createdAt
        claimDocumentUrl
        statusUpdatedAt
        isProcessed
        isOrderAssigned
        approvedOrDeclinedByName
        bonusCriteria
        internalClaimID
        vehicle_category1
        partNumber_category2
        accessoryBasic_category3
        claimHasNotes
        trackingNumber
        orderFundedAt
        AIStatus
      }
      currentPage
      limit
      totalDocs
      totalPages
      url
    }
  }
`
//add the field for notes above
const GET_CLAIM_BY_ID = gql`
  query ClaimById($claimByIdId: String!) {
    claimById(id: $claimByIdId) {
      id
      productId
      productIdManual
      productName
      dateOfSale
      enrolmentId
      firstName
      lastName
      dealerId
      dealerName
      quantity
      sku
      productPurchased
      brand
      awardValue
      bonusValue
      status
      statusUpdatedAt
      orderId
      productDescription
      enrolmentEmail
      declinedReasons
      isProcessed
      createdAt
      claimDocumentUrl
      vehicle_category1
      partNumber_category2
      accessoryBasic_category3
    }
  }
`

const GET_ENROLLMENT = gql`
  query Enrolments(
    $mode: String
    $startDate: String
    $endDate: String
    $fields: [String]
    $limit: Int
  ) {
    enrolments(
      mode: $mode
      startDate: $startDate
      endDate: $endDate
      fields: $fields
      limit: $limit
    ) {
      url
    }
  }
`

const GET_ADDRESS_REPORT = gql`
  query ChangeAddressReport(
    $mode: String
    $startDate: String
    $endDate: String
    $fields: [String]
  ) {
    changeAddressReport(
      mode: $mode
      startDate: $startDate
      endDate: $endDate
      fields: $fields
    ) {
      url
    }
  }
`

const GET_USER_STAT = gql`
  query UserStats {
    userStats {
      dealerName
      earnedLastMonth
      earnedThisMonth
      earnedYearToDate
      email
      name
    }
  }
`

const GET_USERS_WITH_PAGINATION = gql`
  query UsersWithPagination($page: Int, $limit: Int, $searchQuery: String) {
    usersWithPagination(searchQuery: $searchQuery, page: $page, limit: $limit) {
      users {
        _id
        name
        userName
        userEmail
        userType
        authRegionID
        authDistrictID
        authDealerID
        createdAt
        registeredDate
        isActive
        roleData {
          _id
          roleCode
          roleName
        }
      }
      totalPages
      totalDocs
      limit
      currentPage
    }
  }
`

const GET_USER_ROLES = gql`
  query Roles {
    roles {
      createdAt
      _id
      roleCode
      roleName
    }
  }
`

const RESEND_EMAIL_TO_USER = gql`
  query Query($email: String!) {
    resendEnrollmentMailToUser(email: $email)
  }
`

const GET_INCENTIVE_BY_ID = gql`
  query ProductByID($productId: ID!, $incentiveId: String) {
    productByID(productID: $productId, incentiveId: $incentiveId) {
      _id
      productID
      productDescription
      incentiveId
      hasClaims
      rewards {
        _id
        rewardType
        rewardValue
        minProductsSold
        maxProductsSold
        startDate
        endDate
        isRetroActive
      }
    }
  }
`

const GET_TOKEN_FROM_REFRESH_TOKEN = gql`
  query RefreshSessionToken($refreshToken: String) {
    refreshSessionToken(refreshToken: $refreshToken) {
      token
      tokenTime
    }
  }
`

const GET_ALL_ORDERS = gql`
  query AllOrders($page: Int, $limit: Int, $searchQuery: String) {
    allOrders(page: $page, limit: $limit, searchQuery: $searchQuery) {
      currentPage
      limit
      orders {
        _id
        createdAt
        documentUrl
        filename
        funded
        fundedAt
        orderTotal
        status
        orderId
      }
      totalDocs
      totalPages
    }
  }
`

const FUND_ORDER = gql`
  query Query($id: ID!) {
    fundOrderById(id: $id)
  }
`

const GET_REGIONS_BY_USERID = gql`
  query RegionsByUserID {
    regionsByUserID {
      regionId
      regionName
    }
  }
`

const GET_DISTRICTS_BY_REGION_ID = gql`
  query DistrictsByListOfRegionsIds($regionsIds: [String]) {
    districtsByListOfRegionsIds(regionsIds: $regionsIds) {
      districtId
      districtName
      regionId
    }
  }
`

const GET_DEALERS_BY_DISTRICT_ID = gql`
  query DealersByDistrictsIds($districtsIds: [String], $regionIds: [String]) {
    dealersByDistrictsIds(districtsIds: $districtsIds, regionIds: $regionIds) {
      _id
      shopName
    }
  }
`

const GET_EMAIL_BY_ID = gql`
  query EmailsByEnrolmentUserId($page: Int, $limit: Int) {
    emailsByEnrolmentUserId(page: $page, limit: $limit) {
      currentPage
      emailsWithEmailRecipient {
        _id
        enrolmentId
        sentDate
        isSent
        createdAt
        emailId {
          _id
          fromAddress
          subject
          messageBody
          attachmentsUrls
          createdAt
        }
      }
      limit
      totalDocs
      totalPages
    }
  }
`

const GET_EMAIL_BY_USER_ID = gql`
  query EmailsByUserId($page: Int, $limit: Int) {
    emailsByUserId(page: $page, limit: $limit) {
      totalPages
      totalDocs
      limit
      currentPage
      emails {
        _id
        fromAddress
        subject
        messageBody
        attachmentsUrls
        createdAt
      }
    }
  }
`

const GET_MESAAGE = gql`
  query MessagesByEnrolmentUserId($page: Int, $limit: Int) {
    messagesByEnrolmentUserId(page: $page, limit: $limit) {
      totalPages
      totalDocs
      limit
      currentPage
      messagesWithMessageRecipient {
        _id
        enrolmentId
        sentDate
        isSent
        createdAt
        messageId {
          _id
          fromPhoneNumber
          messageBody
          createdAt
        }
      }
    }
  }
`

const GET_MESSAGE_BY_USER_ID = gql`
  query MessagesByUserId($page: Int, $limit: Int) {
    messagesByUserId(page: $page, limit: $limit) {
      totalPages
      totalDocs
      limit
      currentPage
      messages {
        _id
        fromPhoneNumber
        messageBody
        createdAt
      }
    }
  }
`

const PRODUCT_SEARCH = gql`
  query ProductSearch($searchQuery: String) {
    productSearch(searchQuery: $searchQuery) {
      _id
      productID
      productDescription
      combinedField
      category1
      category2
      category3
    }
  }
`

const GET_PRODUCT_CATEGORIES = gql`
  query GetProductCategoriesAndValues {
    getProductCategoriesAndValues {
      categoryKey
      categoryLabel
      values
    }
  }
`

const VERIFY_PHONE_OTP = gql`
  query validateOTP($phone: String, $code: Int) {
    validateOTP(phone: $phone, code: $code)
  }
`
/**
 * API used to get latest file updated date for
 * Dealer uploader and Participant uploader
 */
const GET_DEALER_FILE_UPDATE_TIME = gql`
  query GetDealerFileUpdateTime($fileHash: FileHashType) {
    getLatestFileHashDate(fileHash: $fileHash)
  }
`

const GET_DASHBOARD_STAT = gql`
  query Query($startDate: String, $endDate: String) {
    getAllDashboardStats(startDate: $startDate, endDate: $endDate) {
      claimStatsData {
        approvedClaimsCount
        declinedClaimsCount
        pendingClaimsCount
        totalClaimsCount
      }
      salesClaimMadeDealerWiseData {
        dealerName
        salesClaimMadeCount
      }
      salesClaimMadeOnProductData {
        productName
        salesClaimMadeCount
        productImage
      }
      totalParticipantsStats {
        participantStatsByRegion {
          regionName
          enrolledParticipantsCount
          salesClaimMadeCount
          individualsClaimingCount
        }
        totalEnrolledParticipantsCount
      }
      totalParticipantsStatsByDate {
        participantStatsByRegion {
          enrolledParticipantsCount
          regionName
          salesClaimMadeCount
          individualsClaimingCount
        }
        totalEnrolledParticipantsCount
      }
    }
  }
`
const GET_DASHBOARD_STAT_1 = gql`
  query Query($startDate: String, $endDate: String) {
    getDashboardStats1(startDate: $startDate, endDate: $endDate) {
      claimStatsData {
        approvedClaimsCount
        declinedClaimsCount
        pendingClaimsCount
        totalClaimsCount
      }
      totalParticipantsStats {
        participantStatsByRegion {
          regionName
          enrolledParticipantsCount
          salesClaimMadeCount
          individualsClaimingCount
        }
        totalEnrolledParticipantsCount
      }
      totalParticipantsStatsByDate {
        participantStatsByRegion {
          enrolledParticipantsCount
          regionName
          salesClaimMadeCount
          individualsClaimingCount
        }
        totalEnrolledParticipantsCount
      }
    }
  }
`
const GET_DASHBOARD_STAT_2 = gql`
  query Query($startDate: String, $endDate: String) {
    getDashboardStats2(startDate: $startDate, endDate: $endDate) {
      salesClaimMadeDealerWiseData {
        dealerName
        salesClaimMadeCount
      }
      salesClaimMadeOnProductData {
        productName
        salesClaimMadeCount
        productImage
      }
    }
  }
`

const GET_VAULT_AMOUNT = gql`
  query GetVaultAmount($startDate: String, $endDate: String) {
    getVaultAmount(startDate: $startDate, endDate: $endDate) {
      filledAmount
      totalAmount
      vaultRemainingValue
    }
  }
`

const GET_PARTICIPANTS_REGIONS = gql`
  query ParticipantsStatsOfRegionByDate($startDate: String, $endDate: String) {
    participantsStatsOfRegionByDate(startDate: $startDate, endDate: $endDate) {
      participantStatsByRegion {
        regionName
        enrolledParticipantsCount
        salesClaimMadeCount
        individualsClaimingCount
      }
      totalEnrolledParticipantsCount
    }
  }
`

const GET_PRODUCTS_SKUS = gql`
  query SalesClaimMadeOnProduct($startDate: String, $endDate: String) {
    salesClaimMadeOnProduct(startDate: $startDate, endDate: $endDate) {
      productName
      salesClaimMadeCount
      productImage
    }
  }
`

const GET_DEALERS_DASHBOARD = gql`
  query SalesClaimMadeDealerWise($startDate: String, $endDate: String) {
    salesClaimMadeDealerWise(startDate: $startDate, endDate: $endDate) {
      dealerName
      salesClaimMadeCount
    }
  }
`

const GET_LEADERBOARD_DATA = gql`
  query getLeaderboardDetails(
    $signingInFromConsumerOrAdmin: SigningInFromConsumerOrAdmin
  ) {
    getLeaderboardDetails(
      signingInFromConsumerOrAdmin: $signingInFromConsumerOrAdmin
    ) {
      myCurrentLanding {
        name
        rank
        total
        enrolmentDistrictID
        userEmail
      }
      regionName
      userData {
        name
        rank
        total
        enrolmentDistrictID
        userEmail
        enrolment {
          state
          city
        }
      }
      vaultData {
        filledAmount
        totalAmount
      }
    }
  }
`

/** Current Incentives Report */
const GET_INCENTIVE_REPORT = gql`
  query IncentivesReport($fields: [String]) {
    incentivesReport(fields: $fields) {
      url
    }
  }
`

const GET_ALL_PROMOTIONS = gql`
  query GetAllPromotions {
    getAllPromotions {
      _id
      name
      startDate
      endDate
      amount
    }
  }
`

const GET_ALL_CURRENT_AND_FUTURE_PROMOTIONS = gql`
  query GetAllCurrentAndFuturePromotions {
    getAllCurrentAndFuturePromotions {
      _id
      name
      startDate
      endDate
      amount
      remainingAmount
    }
  }
`

const GET_AI_LABELS = gql`
  query GetAiLabelsByClaimId($claimId: ID!) {
    getAiLabelsByClaimId(claimId: $claimId) {
      getAiLabels {
        key
        value
      }
      claimDocumentUrl
    }
  }
`

const SUBMIT_FOR_RELABELLING = gql`
  mutation SubmitForRelabelling($fileNames: [String]) {
    submitForRelabelling(fileNames: $fileNames)
  }
`

export {
  GET_ALL_ENROLLMENTS,
  GET_MY_PROFILE_DETAILS,
  GET_DEALERS,
  GET_MY_PERMISSIONS,
  GET_CURRENT_INCENTIVES,
  GET_CLAIMS,
  GET_CLAIM_BY_ID,
  GET_ENROLLMENT,
  GET_USER_STAT,
  GET_USERS_WITH_PAGINATION,
  GET_USER_ROLES,
  RESEND_EMAIL_TO_USER,
  GET_ANNOUNCEMENTS,
  GET_INCENTIVE_BY_ID,
  GET_HEADER,
  GET_TOKEN_FROM_REFRESH_TOKEN,
  GET_ALL_ORDERS,
  FUND_ORDER,
  GET_REGIONS_BY_USERID,
  GET_DISTRICTS_BY_REGION_ID,
  GET_DEALERS_BY_DISTRICT_ID,
  GET_EMAIL_BY_ID,
  GET_EMAIL_BY_USER_ID,
  GET_ADDRESS_REPORT,
  GET_MESAAGE,
  GET_MESSAGE_BY_USER_ID,
  PRODUCT_SEARCH,
  GET_PRODUCT_CATEGORIES,
  VERIFY_PHONE_OTP,
  GET_DEALER_FILE_UPDATE_TIME,
  GET_DASHBOARD_STAT,
  GET_PARTICIPANTS_REGIONS,
  GET_PRODUCTS_SKUS,
  GET_DEALERS_DASHBOARD,
  GET_INCENTIVE_DETAILS_BY_CATEGORY_3,
  GET_LEADERBOARD_DATA,
  GET_PRE_ENROLLMENT_DATA,
  GET_DISTINCT_VEHICLES,
  GET_PART_NUMBER_BY_VEHICLE,
  GET_ACCESSORY_BY_PART_NUMBER,
  GET_INCENTIVE_REPORT,
  GET_VAULT_AMOUNT,
  GET_FUNDING_HISTORY,
  GET_ALL_PROMOTIONS,
  GET_ALL_CURRENT_AND_FUTURE_PROMOTIONS,
  GET_AI_LABELS,
  SUBMIT_FOR_RELABELLING,
  GET_DASHBOARD_STAT_1,
  GET_DASHBOARD_STAT_2,
}
